import React from 'react'
import { graphql } from 'gatsby'

import PATHS from 'paths'
import PartnerLandingPageLayout from 'layouts/PartnerLandingPageLayout'
import { PRODUCTS } from 'lib/products/constants'
import JSONLD from 'components/JSONLD'
import PROBATE_JSONLD from 'lib/json-ld/probate'
import { getQueryParameters } from 'lib/url/getQueryParameters'
import Content from 'views/probate/public/Apply/Content'
import { TRUSTPILOT_RATING_OUT_OF_5, TRUSTPILOT_REVIEWS_TOTAL } from 'config'
import { roundDownToNearestHundred } from 'lib/formatting/numbers'

interface PartnerLandingPageProps {
  data: {
    page: {
      partner: {
        bannerBackgroundColor: string
        bannerLogoAlignment: 'left' | 'right'
        bannerTextColor: string
        logo: { file: { url: string }; title: string }
        utmChannel: string
        utmSource: string
        hideFarewillLogoInHeader: boolean | null
        partnerId: string
      }
      bannerText: { bannerText: string }
      heroCtaText: string | null
      heroSubtitle: { heroSubtitle: string } | null
      heroTitle: string | null
    }
  }
}

const PartnerLandingPage = ({
  data: {
    page: { partner, heroTitle, heroSubtitle, heroCtaText, bannerText },
  },
}: PartnerLandingPageProps): React.ReactElement => {
  const { utm_campaign: utmCampaign } = getQueryParameters()

  return (
    <>
      <JSONLD data={[PROBATE_JSONLD]} />
      <PartnerLandingPageLayout
        title="Need Help With Probate? | Apply For Probate The Simpler Way"
        description={`Our award-winning team makes applying for probate quick, easy and affordable. Free phone support. Rated ${TRUSTPILOT_RATING_OUT_OF_5} out of 5 by over ${roundDownToNearestHundred(
          TRUSTPILOT_REVIEWS_TOTAL
        )} customers. Client Care Initiative of the Year 2020.`}
        product={PRODUCTS.PROBATE}
        canonicalPath={PATHS.PROBATE.OVERVIEW}
        meta={[
          {
            name: 'robots',
            content: 'noindex',
          },
        ]}
        utmCampaign={utmCampaign as string}
        partner={partner}
        bannerText={bannerText?.bannerText}
      >
        <Content
          ctaText={heroCtaText || undefined}
          subtitle={heroSubtitle?.heroSubtitle}
          title={heroTitle || undefined}
        />
      </PartnerLandingPageLayout>
    </>
  )
}

export const query = graphql`
  query PartnerLandingPageProbate($slug: String!) {
    page: contentfulPartnerLandingPageProbate(slug: { eq: $slug }) {
      partner {
        bannerTextColor
        bannerBackgroundColor
        bannerLogoAlignment
        logo {
          file {
            url
          }
          title
        }
        utmChannel
        utmSource
        hideFarewillLogoInHeader
        partnerId
      }
      heroTitle
      heroSubtitle {
        heroSubtitle
      }
      heroCtaText
      bannerText {
        bannerText
      }
    }
  }
`

export default PartnerLandingPage
